// @ts-nocheck
/**
 * squadbox.ai API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssistantDocumentToAddDto } from './assistantDocumentToAddDto';


export interface CreateAssistantDto { 
    /**
     * The chat assistant name
     */
    name: string;
    /**
     * The chat assistant description
     */
    description: string;
    /**
     * The chat assistant prompt
     */
    prompt: string;
    /**
     * The chat assistant visibility
     */
    visibility: CreateAssistantDto.VisibilityEnum;
    /**
     * The chat assistant logo
     */
    logo?: string;
    /**
     * The transaction uuid for the ingestion of documents. Should be given when creating an assistant with documents.
     */
    transactionId?: string;
    /**
     * The list of filenames (with extension) of the uploaded documents for that assistant.
     */
    documentsToAdd?: Array<AssistantDocumentToAddDto>;
}
export namespace CreateAssistantDto {
    export type VisibilityEnum = 'public' | 'private' | 'shared';
    export const VisibilityEnum = {
        public: 'public' as VisibilityEnum,
        private: 'private' as VisibilityEnum,
        shared: 'shared' as VisibilityEnum
    };
}



import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import * as fromGenerated from '../../../_generated';
import {
  AssistantActions,
  AssistantModalActions,
  AssistantsActions,
} from './assistants.actions';

export const assistantsFeatureKey = 'assistants';

export type AssistantsFilterByType = 'byHalfserious' | 'ByYourTeam' | 'byYou';

export interface AssistantsState
  extends EntityState<fromGenerated.AssistantView> {
  selectedAssistantId: string | null;
  error: string | null;
  filterBy: AssistantsFilterByType | null;
  isSaveAssistantModalOpened: boolean;
}

const entityAdapter: EntityAdapter<fromGenerated.AssistantView> =
  createEntityAdapter<fromGenerated.AssistantView>({
    selectId: (view) => view.uuid,
    sortComparer: (a, b) => (a.name > b.name ? 1 : -1),
  });

export const initialAssistantsState: AssistantsState =
  entityAdapter.getInitialState({
    selectedAssistantId: null,
    error: null,
    filterBy: null,
    isSaveAssistantModalOpened: false,
  });

export const assistantsFeature = createFeature({
  name: assistantsFeatureKey,
  reducer: createReducer(
    initialAssistantsState,
    on(AssistantsActions.loadAssistantsSuccess, (state, { assistants }) =>
      entityAdapter.setAll(assistants, { ...state, error: null })
    ),
    on(AssistantActions.selectAssistant, (state, { uuid }) => ({
      ...state,
      selectedAssistantId: uuid,
    })),
    on(AssistantActions.unselectAssistant, (state) => ({
      ...state,
      selectedAssistantId: null,
    })),
    on(AssistantActions.addAssistantSuccess, (state, { uuid }) =>
      entityAdapter.updateOne(
        {
          id: uuid,
          changes: { active: true },
        },
        { ...state, error: null }
      )
    ),
    on(AssistantActions.addAssistantFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(AssistantActions.removeAssistantSuccess, (state, { uuid }) =>
      entityAdapter.updateOne(
        {
          id: uuid,
          changes: { active: false },
        },
        { ...state, error: null }
      )
    ),
    on(AssistantActions.removeAssistantFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(AssistantModalActions.openSaveAssistantModal, (state) => ({
      ...state,
      isSaveAssistantModalOpened: true,
    })),
    on(AssistantModalActions.closeSaveAssistantModal, (state) => ({
      ...state,
      isSaveAssistantModalOpened: false,
    })),
    on(AssistantModalActions.saveAssistantSuccess, (state, { assistant }) =>
      entityAdapter.upsertOne(assistant, { ...state, error: null })
    ),
    on(AssistantModalActions.saveAssistantFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(AssistantModalActions.editAssistantSuccess, (state, { assistant }) =>
      entityAdapter.updateOne(
        { id: assistant.uuid, changes: assistant },
        { ...state, error: null }
      )
    ),
    on(AssistantModalActions.editAssistantFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(AssistantModalActions.unsetError, (state) => ({
      ...state,
      error: null,
    })),
    on(
      AssistantActions.receivedAssistantStatusUpdatedFromSocket,
      (state, { assistant }) =>
        entityAdapter.updateOne(
          { id: assistant.uuid, changes: assistant },
          { ...state }
        )
    )
  ),
  extraSelectors: ({ selectAssistantsState, selectSelectedAssistantId }) => {
    const entitySelectors = entityAdapter.getSelectors(selectAssistantsState);

    return {
      ...entitySelectors,
      selectSelectedAssistant: createSelector(
        selectSelectedAssistantId,
        entitySelectors.selectEntities,
        (selectedAssistantId, entities) =>
          selectedAssistantId ? entities[selectedAssistantId] : null
      ),
      selectActiveAssistants: createSelector(
        entitySelectors.selectAll,
        (assistants) => assistants.filter((assistant) => assistant.active)
      ),
    };
  },
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import * as fromGenerated from '../../_generated';

export const ChatModalActions = createActionGroup({
  source: 'ChatModal',
  events: {
    'Open Delete Chat Modal': emptyProps(),
    'Close Delete Chat Modal': emptyProps(),
  },
});

export const ChatActions = createActionGroup({
  source: 'Chat',
  events: {
    'Select Chat': props<{
      id: number;
    }>(),
    'Unselect Chat': emptyProps(),
    'Toggle Loading': props<{
      isLoading: boolean;
    }>(),
    'Load Empty Chat': emptyProps(),
    'Open Side Container': emptyProps(),
    'Close Side Container': emptyProps(),
    'Open Threads Listing Container': emptyProps(),
    'Close Threads Listing Container': emptyProps(),
  },
});

export const ChatsActions = createActionGroup({
  source: 'Chats',
  events: {
    'Add Chat': props<{ title?: string }>(),
    'Add Chat Success': props<{ chat: fromGenerated.ChatView }>(),
    'Add Chat Failure': props<{ error: string }>(),
    'Load Chats': emptyProps(),
    'Load Chats Success': props<{ chats: fromGenerated.ChatView[] }>(),
    'Load Chats Failure': props<{ error: string }>(),
    'Delete Chat': props<{
      id: number;
    }>(),
    'Delete Chat Success': props<{ id: number }>(),
    'Delete Chat Failure': props<{ error: string }>(),
  },
});

export const ChatWithMessagesActions = createActionGroup({
  source: 'ChatWithMessages',
  events: {
    'Load ChatWithMessages': props<{
      id: number;
    }>(),
    'Load ChatWithMessages Success': props<{
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>(),
    'Load ChatWithMessages Failure': props<{ error: string }>(),
    'Add ChatMessage': props<{
      content: string;
      assistant?: { uuid: string; description: string };
    }>(),
    'Add ChatMessage Success': emptyProps(),
    'Add ChatMessage Failure': props<{ error: string }>(),
    'Received ChatWithMessages from Socket Success': props<{
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>(),
  },
});

export const ChatsSocketActions = createActionGroup({
  source: 'ChatsSocket',
  events: {
    Reconnect: emptyProps(),
  },
});

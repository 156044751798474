import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLayout from '../../../layout';

@Component({
  selector: 'squadbox-assistant-logo-dropdown',
  standalone: true,
  template: `
    @for (logo of logos(); track logo) {
    <button
      class="
          flex items-center justify-center
          size-12 rounded-full
          outline outline-primary-500/15 hover:outline-primary-500/25
          bg-shades-white hover:bg-neutral-50
        "
      (click)="onClickLogo(logo)"
    >
      <img class="size-6" [src]="logo" />
    </button>
    }
  `,
  styles: `
  :host {
    @apply absolute top-24 right-4 w-16 flex flex-col items-center space-y-2
    bg-shades-white rounded-lg p-2 z-10 border border-neutral-200;
  }
  :host(.is-mobile) {
    @apply top-20;
  }
  `,
})
export class AssistantLogoDropdownComponent {
  public readonly logos = input.required<string[]>();
  public readonly logoClicked = output<string>();
  public readonly clickedOutside = output<boolean>();

  private readonly layoutStore = inject(Store<fromLayout.LayoutState>);
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );

  @HostBinding('class.is-mobile') get isMobileHost() {
    return this.isMobile();
  }

  constructor(private elementRef: ElementRef) {}

  public onClickLogo(logo: string) {
    this.logoClicked.emit(logo);
  }

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (
      targetElement &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      !this.elementRef.nativeElement.contains(targetElement)
    ) {
      this.clickedOutside.emit(true);
    }
  }
}

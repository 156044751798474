import { Component, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import { debounceTime, map, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import * as fromGenerated from '../../../_generated';
import * as fromFeatureFlags from '../../../feature-flags';
import * as fromLayout from '../../../layout';
import { AssistantModalActions } from '../store/assistants.actions';
import {
  AssistantsState,
  assistantsFeature,
} from '../store/assistants.reducer';
import { FileActions } from '../store/files.actions';
import {
  FileUploadState,
  FilesState,
  filesFeature,
} from '../store/files.reducer';
import { AssistantLogoDropdownComponent } from './assistant-logo-dropdown.component';
import {
  FileUploadAction,
  FileUploadComponent,
  FileWithId,
} from './file-upload.component';

@Component({
  selector: 'squadbox-assistant-save-modal',
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    fromSeriousSystem.ModalComponent,
    fromSeriousSystem.InputComponent,
    fromSeriousSystem.TextareaComponent,
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.AvatarDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromFeatureFlags.FeatureFlagsDirective,
    FileUploadComponent,
    AssistantLogoDropdownComponent,
  ],
  template: `
    <sd-modal
      [isModalVisible]="isSaveAssistantModalOpened()"
      [modalOptions]="{
        title: 'ASSISTANTS.SAVE_MODAL.TITLE' | translate,
        showCloseButton: !isMobile(),
        isFullscreen: isMobile(),
        actions: [
          {
            label: 'ASSISTANTS.SAVE_MODAL.CTA_CANCEL' | translate,
            action: 'cancel',
            type: 'cancel',
            disabled: false,
          },
          {
            label: 'ASSISTANTS.SAVE_MODAL.CTA_SAVE' | translate,
            action: 'save',
            type: 'save',
            disabled: isSaveAssistantFormDisabled(),
          },
        ],
      }"
      (triggerAction)="handleDesktopModalTriggerActionCTA($event)"
    >
      <form
        class="flex flex-col gap-4 w-full tablet-portrait:min-w-150"
        [formGroup]="saveAssistantForm"
        [class.h-full]="isMobile()"
      >
        <div class="flex flex-row justify-between gap-2">
          <div class="flex-grow">
            <sd-input
              class="flex-grow"
              [class.max-w-44]="isMobile()"
              formControlName="name"
              [label]="'ASSISTANTS.SAVE_MODAL.FORM.NAME.LABEL' | translate"
              [placeholder]="
                'ASSISTANTS.SAVE_MODAL.FORM.NAME.PLACEHOLDER' | translate
              "
              [limit]="40"
              [showError]="
                (saveAssistantForm.get('name')?.dirty &&
                  !saveAssistantForm.get('name')?.valid) ||
                isDuplicateNameError()
              "
              [errorMessage]="nameErrorMessage()"
              (valueChanged)="handleNameInputChange()"
            ></sd-input>
          </div>
          <div class="flex flex-col gap-2 w-24 relative">
            <p
              class="typo-caption font-semibold leading-4 h-5 text-center px-1 py-0.5"
            >
              {{ 'ASSISTANTS.SAVE_MODAL.FORM.IMAGE.LABEL' | translate }}
            </p>
            @if (saveAssistantForm.get('logo')?.value) {
            <button
              class="
                flex items-center justify-center
                size-12 rounded-full mx-auto
                outline outline-primary-500/15 hover:outline-primary-500/25
                bg-shades-white hover:bg-neutral-50
              "
              (click)="toggleLogoDropdown()"
            >
              <img
                class="size-6"
                [src]="saveAssistantForm.get('logo')?.value"
              />
            </button>
            } @else {
            <div
              sdAvatar
              size="lg"
              color="light"
              class="mx-auto"
              (click)="toggleLogoDropdown()"
            >
              <svg sdUseUIBasicIcon="plus"></svg>
            </div>
            } @if (isLogoDropdownVisible()) {
            <squadbox-assistant-logo-dropdown
              [logos]="PREDEFINED_LOGO_PATHS"
              (logoClicked)="handleLogoClick($event)"
              (clickedOutside)="handleLogoDropdownOutsideClick($event)"
            ></squadbox-assistant-logo-dropdown>
            }
          </div>
        </div>
        <sd-textarea
          class="[&>div>textarea]:h-20"
          formControlName="description"
          [label]="'ASSISTANTS.SAVE_MODAL.FORM.DESCRIPTION.LABEL' | translate"
          [placeholder]="
            'ASSISTANTS.SAVE_MODAL.FORM.DESCRIPTION.PLACEHOLDER' | translate
          "
          [limit]="200"
        ></sd-textarea>

        <squadbox-file-upload
          label="Select Source"
          placeholder="Select a source"
          formControlName="files"
          [filesLoaders]="filesLoaders()"
          (valueChanged)="handleFileInputChange($event)"
          [squadboxFeatureFlag]="'ff-create-ai-assistant-with-document-s'"
        ></squadbox-file-upload>

        <sd-textarea
          class="
            [&>div]:h-full [&>div>textarea]:flex-grow
            tablet-portrait:[&>div>textarea]:h-70
          "
          [class.flex-grow]="isMobile()"
          formControlName="prompt"
          [label]="'ASSISTANTS.SAVE_MODAL.FORM.PROMPT.LABEL' | translate"
          [placeholder]="
            'ASSISTANTS.SAVE_MODAL.FORM.PROMPT.PLACEHOLDER' | translate
          "
          [limit]="assistantPromptMaxLength"
        ></sd-textarea>
      </form>
    </sd-modal>

    <sd-modal
      [isModalVisible]="isConfirmCancelModalOpened()"
      [modalOptions]="{
        title: 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.TITLE' | translate,
        showCloseButton: false,
        actions: [
          {
            label: 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.CTA_CANCEL' | translate,
            action: 'cancel',
            type: 'cancel',
            disabled: false,
          },
          {
            label: 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.CTA_DISCARD' | translate,
            action: 'delete',
            type: 'delete',
            disabled: false,
          },
        ],
        backdropDismiss: true
      }"
      (triggerAction)="handleDesktopModalConfirmCancelCTA($event)"
    >
      <div class="w-auto tablet-portrait:w-120">
        {{ 'ASSISTANTS.SAVE_MODAL_CONFIRM_CANCEL.DESCRIPTION' | translate }}
      </div>
    </sd-modal>
  `,
  styles: [``],
})
export class AssistantModalComponent {
  private readonly translateService = inject(TranslateService);
  private readonly layoutStore = inject(Store<fromLayout.LayoutState>);
  private readonly assistantsStore = inject(Store<AssistantsState>);
  private readonly filesStore = inject(Store<FilesState>);
  private readonly actions$ = inject(Actions);

  public readonly assistantPromptMaxLength =
    environment.assistantPromptMaxLength;

  public readonly selectedAssistant = this.assistantsStore.selectSignal(
    assistantsFeature.selectSelectedAssistant
  );
  public readonly isMobile = this.layoutStore.selectSignal(
    fromLayout.layoutFeature.selectIsMobile
  );
  public readonly filesLoaders = this.filesStore.selectSignal(
    filesFeature.selectFilesLoaders
  );

  public readonly isDuplicateNameError = computed(() => {
    const error = this.assistantsStore.selectSignal(
      assistantsFeature.selectError
    )();
    const duplicationNameErrors = [
      'ASSISTANTS_UPDATE_UNIQUE_CONSTRAINT_ERROR',
      'ASSISTANTS_CREATE_UNIQUE_CONSTRAINT_ERROR',
      'ASSISTANTS_CREATE_WITH_DOCUMENTS_UNIQUE_CONSTRAINT_ERROR',
      'ASSISTANTS_UPDATE_WITH_DOCUMENTS_UNIQUE_CONSTRAINT_ERROR',
    ];

    return error ? duplicationNameErrors.includes(error) : false;
  });

  public readonly nameErrorMessage = computed(() => {
    let message: string | undefined;
    if (this.isDuplicateNameError()) {
      message = this.translateService.instant(
        'ASSISTANTS.SAVE_MODAL.FORM.NAME.DUPLICATED_NAME_ERROR_PART_1'
      ) as string;

      if (!this.isMobile()) {
        message += this.translateService.instant(
          'ASSISTANTS.SAVE_MODAL.FORM.NAME.DUPLICATED_NAME_ERROR_PART_2'
        ) as string;
      }
    }

    return message;
  });

  public readonly saveAssistantForm = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [
        (control) => Validators.required(control),
        Validators.minLength(2),
        Validators.maxLength(40),
      ],
    }),
    logo: new FormControl('', {
      nonNullable: true,
      validators: [(control) => Validators.required(control)],
    }),
    description: new FormControl('', {
      nonNullable: true,
      validators: [
        (control) => Validators.required(control),
        Validators.minLength(2),
        Validators.maxLength(200),
      ],
    }),
    files: new FormControl<FileUploadState[]>(
      {
        value: [],
        disabled: false,
      },
      { nonNullable: true }
    ),
    prompt: new FormControl('', {
      nonNullable: true,
      validators: [
        (control) => Validators.required(control),
        Validators.minLength(2),
        Validators.maxLength(this.assistantPromptMaxLength),
      ],
    }),
    visibility: new FormControl(
      {
        value: fromGenerated.CreateAssistantDto.VisibilityEnum.private,
        disabled: true,
      },
      {
        nonNullable: true,
        validators: [
          (control) => Validators.required(control),
          Validators.pattern(
            `/^(${fromGenerated.CreateAssistantDto.VisibilityEnum.private}|${fromGenerated.CreateAssistantDto.VisibilityEnum.public})$/`
          ),
        ],
      }
    ),
  });
  public readonly saveAssistantFormValidity = toSignal(
    this.saveAssistantForm.valueChanges.pipe(
      debounceTime(300),
      map(() => this.saveAssistantForm.valid)
    ),
    {}
  );
  public readonly isSaveAssistantModalOpened =
    this.assistantsStore.selectSignal(
      assistantsFeature.selectIsSaveAssistantModalOpened
    );
  public readonly isConfirmCancelModalOpened = signal(false);

  public readonly PREDEFINED_LOGO_PATHS = [
    '/assets/illustrations/folder.png',
    '/assets/illustrations/convention.png',
    '/assets/illustrations/files.png',
    '/assets/illustrations/bubble.png',
    '/assets/illustrations/knowledge.png',
  ];
  public readonly isLogoDropdownVisible = signal(false);

  private readonly fileUploadTransactionId = signal<string>('');

  constructor() {
    this.assistantsStore
      .select(assistantsFeature.selectSelectedAssistant)
      .pipe(takeUntilDestroyed())
      .subscribe((selectedAssistant) => {
        if (selectedAssistant) {
          const files =
            (selectedAssistant.documents?.map((doc) => ({
              id: doc.uuid,
              name: doc.filename,
              extension: doc.extension,
              size: doc.size,
            })) as FileUploadState[]) ?? [];

          this.filesStore.dispatch(
            FileActions.upsertFiles({
              files,
            })
          );

          this.saveAssistantForm.patchValue({
            ...selectedAssistant,
            files,
          });
        }
      });

    this.actions$
      .pipe(
        takeUntilDestroyed(),
        ofType(AssistantModalActions.openSaveAssistantModal),
        tap(() => this.saveAssistantForm.reset()),
        tap(() =>
          this.assistantsStore.dispatch(AssistantModalActions.unsetError())
        ),
        tap(() => {
          this.fileUploadTransactionId.set(uuidv4());
        })
      )
      .subscribe();
  }

  public isSaveAssistantFormDisabled = computed(() => {
    const isFileLoading = this.filesStore.selectSignal(
      filesFeature.selectAreFilesLoading
    )();

    if (isFileLoading) {
      return true;
    }

    return !(this.saveAssistantFormValidity() && !this.isDuplicateNameError());
  });

  public handleDesktopModalTriggerActionCTA(action: string): void {
    switch (action as fromSeriousSystem.ModalAction['type']) {
      case 'save':
        return this.saveAssistantForm.valid
          ? this.handleSaveAssistant()
          : this.handleFormError();
      case 'cancel':
        if (this.isSaveAssistantFormDirty()) {
          this.isConfirmCancelModalOpened.set(true);
        } else {
          this.assistantsStore.dispatch(
            AssistantModalActions.closeSaveAssistantModal()
          );
          this.saveAssistantForm.reset();
        }
        break;
    }
  }

  public handleDesktopModalConfirmCancelCTA(action: string): void {
    switch (action as fromSeriousSystem.ModalAction['type']) {
      case 'delete':
        this.assistantsStore.dispatch(
          AssistantModalActions.closeSaveAssistantModal()
        );
        this.saveAssistantForm.reset();
        break;
      case 'cancel':
        this.isConfirmCancelModalOpened.set(false);
        break;
    }

    this.isConfirmCancelModalOpened.set(false);
  }

  public handleNameInputChange() {
    if (this.isDuplicateNameError()) {
      this.assistantsStore.dispatch(AssistantModalActions.unsetError());
    }
  }

  public toggleLogoDropdown() {
    this.isLogoDropdownVisible.set(!this.isLogoDropdownVisible());
  }

  public handleLogoClick(logo: string) {
    this.isLogoDropdownVisible.set(false);
    const logoFormControl = this.saveAssistantForm.get('logo');
    logoFormControl?.setValue(logo);
    logoFormControl?.markAsDirty();
  }

  public handleLogoDropdownOutsideClick(clickedOutside: boolean) {
    if (clickedOutside && this.isLogoDropdownVisible()) {
      this.isLogoDropdownVisible.set(false);
    }
  }

  public handleFileInputChange(input: {
    file: FileWithId;
    action: FileUploadAction;
  }): void {
    const filesControl = this.saveAssistantForm.get('files');

    if (!filesControl) {
      return console.warn('No filesControl found.');
    }

    if (input.action === FileUploadAction.DELETE) {
      return;
    }

    this.filesStore.dispatch(
      FileActions.uploadFile({
        transactionId: this.fileUploadTransactionId(),
        id: input.file.id,
        name: input.file.name,
        extension: input.file.type,
        size: input.file.size,
        file: input.file,
      })
    );
  }

  private handleSaveAssistant() {
    const selectedAssistant = this.selectedAssistant();
    const isEditing = !!selectedAssistant;
    const transactionId = this.fileUploadTransactionId();

    const assistantDto: fromGenerated.CreateAssistantDto = {
      name: this.saveAssistantForm.controls.name.value,
      logo: this.saveAssistantForm.controls.logo.value,
      description: this.saveAssistantForm.controls.description.value,
      prompt: this.saveAssistantForm.controls.prompt.value,
      visibility: this.saveAssistantForm.controls.visibility.value,
    };

    if (isEditing) {
      if (!selectedAssistant) return;

      this.handleEditAssistant(
        selectedAssistant,
        assistantDto,
        this.saveAssistantForm.controls.files.value,
        transactionId
      );
    } else {
      this.handleCreateAssistant(
        assistantDto,
        this.saveAssistantForm.controls.files.value,
        transactionId
      );
    }
  }

  private handleCreateAssistant(
    assistantDto: fromGenerated.CreateAssistantDto,
    files: FileUploadState[],
    transactionId: string
  ) {
    const isCreatingWithDocuments = files.length > 0;

    if (isCreatingWithDocuments) {
      assistantDto = {
        ...assistantDto,
        transactionId,
        documentsToAdd: files.map((file) => ({
          documentId: file.id,
          filename: file.name,
        })),
      };
    }

    this.assistantsStore.dispatch(
      AssistantModalActions.saveAssistant({ assistant: assistantDto })
    );
  }

  private handleEditAssistant(
    selectedAssistant: fromGenerated.AssistantView,
    assistantDto: fromGenerated.UpdateAssistantDto,
    files: FileUploadState[],
    transactionId: string
  ) {
    // Get existing documents
    const existingDocuments = selectedAssistant.documents ?? [];

    // Find newly added documents
    const documentsToAdd = files
      .filter(
        (file) => !existingDocuments.some((doc) => doc.filename === file.name)
      )
      .map((file) => ({
        documentId: file.id,
        filename: file.name,
      }));

    // Find the IDs of deleted documents
    const documentIdsToDelete = existingDocuments
      .filter((doc) => !files.map((file) => file.id).includes(doc.uuid))
      .map((doc) => doc.uuid);

    // If documents have been added
    if (documentsToAdd.length > 0) {
      assistantDto = {
        ...assistantDto,
        transactionId,
        documentsToAdd,
      };
    }

    // If documents have been removed
    if (documentIdsToDelete.length > 0) {
      assistantDto = {
        ...assistantDto,
        transactionId,
        documentIdsToDelete,
      };
    }

    this.assistantsStore.dispatch(
      AssistantModalActions.editAssistant({
        uuid: selectedAssistant.uuid,
        assistant: assistantDto,
      })
    );
  }

  private handleFormError() {
    console.error('Form is invalid');
    for (const controlName in this.saveAssistantForm.controls) {
      const control = this.saveAssistantForm.get(controlName);
      if (control?.errors) {
        console.error(`Control ${controlName} is invalid:`, control.errors);
      }
    }
  }

  private isSaveAssistantFormDirty() {
    return this.selectedAssistant()
      ? Object.entries(this.saveAssistantForm.value)
          .filter(([key]) => key !== 'files')
          .some(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            ([key, value]) => value !== (this.selectedAssistant() as any)?.[key]
          )
      : this.saveAssistantForm.dirty;
  }
}

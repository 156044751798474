import { createActionGroup, props } from '@ngrx/store';
import { FileUploadState } from './files.reducer';

export const FileActions = createActionGroup({
  source: 'File',
  events: {
    'Upload File': props<
      Omit<FileUploadState, 'isLoading'> & {
        transactionId: string;
        file: File;
      }
    >(),
    'Upload File Success': props<{ id: string }>(),
    'Upload File Failure': props<{
      id: string;
      error: string;
    }>(),
    'Upsert Files': props<{
      files: FileUploadState[];
    }>(),
  },
});

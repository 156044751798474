import { FileTypesIconsType } from '@serious-system';

export const MB_IN_BYTES = 1024 * 1024;
export const MAX_TOTAL_FILE_SIZE_MB = 50;
export const SUPPORTED_FILE_TYPES = ['pdf'];
export const calculateFileSizeMB = (file: File): number =>
  file.size / MB_IN_BYTES;

export const calculateTotalFileSize = (files: File[]): number =>
  files.reduce((total, file) => total + calculateFileSizeMB(file), 0);

export const isFileSizeValid = (file: File, files: File[]): boolean => {
  const newFileSize = calculateFileSizeMB(file);
  const filesWithoutCurrent = files.filter((f) => f.name !== file.name);
  const currentTotalSize = calculateTotalFileSize(filesWithoutCurrent);

  if (newFileSize > MAX_TOTAL_FILE_SIZE_MB) {
    return false;
  }

  return currentTotalSize + newFileSize <= MAX_TOTAL_FILE_SIZE_MB;
};

export const filterExceededFilesSize = (
  currentFile: File,
  files: File[]
): File[] => {
  const filesWithoutCurrent = files.filter(
    (file) => file.name !== currentFile.name
  );

  if (isFileSizeValid(currentFile, filesWithoutCurrent)) {
    return [...filesWithoutCurrent, currentFile];
  }

  return filesWithoutCurrent;
};

export const filterOnlyUniqueFiles = (
  newFiles: File[],
  files: File[]
): File[] =>
  newFiles.filter(
    (newFile: File) =>
      !files.some((existingFile) => existingFile.name === newFile.name)
  );

export const isValidFileType = (file: File): boolean =>
  file.type === 'application/pdf' || file.name.toLowerCase().endsWith('.pdf');

export const getFileType = (type: string): FileTypesIconsType =>
  (type?.split('/')[1] as FileTypesIconsType) ?? 'pdf';

export const calculateTotalFileSizeMB = (files: File[]): number => {
  if (!files) {
    return 0;
  }

  return files.reduce((total: number, file: File) => {
    return total + calculateFileSizeMB(file);
  }, 0);
};

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { FileActions } from './files.actions';

export const filesFeatureKey = 'files';

export interface FileUploadState {
  id: string;
  name: string;
  extension: string;
  size: number;
  isLoading: boolean;
}

export interface FilesState extends EntityState<FileUploadState> {
  error: string | null;
}

const entityAdapter: EntityAdapter<FileUploadState> =
  createEntityAdapter<FileUploadState>({
    selectId: (file) => file.id,
  });

export const initialFilesState: FilesState = entityAdapter.getInitialState({
  error: null,
});

export const filesFeature = createFeature({
  name: filesFeatureKey,
  reducer: createReducer(
    initialFilesState,
    on(FileActions.uploadFile, (state, { id, file }) =>
      entityAdapter.upsertOne(
        {
          id,
          name: file.name,
          extension: file.type,
          size: file.size,
          isLoading: true,
        },
        { ...state, error: null }
      )
    ),
    on(FileActions.uploadFileSuccess, (state, { id }) =>
      entityAdapter.updateOne(
        {
          id,
          changes: {
            isLoading: false,
          },
        },
        { ...state, error: null }
      )
    ),
    on(FileActions.uploadFileFailure, (state, { id, error }) =>
      entityAdapter.updateOne(
        {
          id,
          changes: {
            isLoading: false,
          },
        },
        { ...state, error }
      )
    ),
    on(FileActions.upsertFiles, (state, { files }) =>
      entityAdapter.upsertMany(files, state)
    )
  ),
  extraSelectors: ({ selectFilesState }) => {
    const entitySelectors = entityAdapter.getSelectors(selectFilesState);

    return {
      ...entitySelectors,
      selectAreFilesLoading: createSelector(
        entitySelectors.selectAll,
        (files) => files.some((file) => file.isLoading)
      ),
      selectFilesLoaders: createSelector(entitySelectors.selectAll, (files) =>
        files.reduce(
          (acc, file) => ({
            ...acc,
            [file.id]: { id: file.id, isLoading: file.isLoading },
          }),
          {}
        )
      ),
    };
  },
});

import { Component, computed, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../../_generated';

type AssistantStatusType = `${fromGenerated.AssistantView['status']}`;

@Component({
  selector: 'squadbox-assistant-card',
  imports: [
    fromSeriousSystem.ButtonDirective,
    fromSeriousSystem.TooltipDirective,
    TranslateModule,
  ],
  template: `
    <div
      [class.grid-template-area]="'logo'"
      class="
        flex items-center justify-center mr-2
        size-12 rounded-full bg-primary-500/5
        outline outline-primary-500/15 outline-offset-4
        cursor-pointer
      "
      [class.pointer-events-none]="!isEditable()"
      (click)="handleEditClickedCTA()"
    >
      <img
        [class.opacity-50]="isProcessingOrFailed()"
        [src]="logo()"
        alt="Assistant Logo"
        class="size-5"
      />
    </div>
    <div
      [class.grid-template-area]="'text'"
      [class.opacity-50]="isProcessingOrFailed()"
      class="flex flex-col flex-grow gap-1 cursor-pointer"
      [class.pointer-events-none]="!isEditable()"
      (click)="handleEditClickedCTA()"
    >
      <div class="typo-p3 font-semibold text-neutral-700">
        {{ name() }}
      </div>
      <div class="typo-caption text-neutral-500 line-clamp-2">
        {{ description() }}
      </div>
    </div>
    <div [class.grid-template-area]="'action'" class="pl-6">
      @switch (status()) { @case ('processing') {
      <div
        [sdTooltip]="'ASSISTANTS.CARD.PROCESSING_STATUS_TOOLTIP' | translate"
      >
        <div
          sdButton
          class="pointer-events-none"
          color="primary"
          variant="tonal"
          size="xs"
        >
          {{ 'ASSISTANTS.CARD.PROCESSING_STATUS' | translate }}
        </div>
      </div>
      } @case ('failed') {
      <div [sdTooltip]="'ASSISTANTS.CARD.FAILED_STATUS_TOOLTIP' | translate">
        <div
          sdButton
          class="pointer-events-none"
          color="error"
          variant="tonal"
          size="xs"
        >
          {{ 'ASSISTANTS.CARD.FAILED_STATUS' | translate }}
        </div>
      </div>
      } @case ('ready') { @if (!isAdded()) {
      <button sdButton color="primary" size="xs" (click)="addClicked.emit()">
        {{ 'ASSISTANTS.CARD.ADD_ASSISTANT' | translate }}
      </button>
      } @else {
      <button
        sdButton
        color="neutral"
        variant="outline"
        size="xs"
        (click)="removeClicked.emit()"
      >
        {{ 'ASSISTANTS.CARD.REMOVE_ASSISTANT' | translate }}
      </button>
      } } }
    </div>
  `,
  styles: [
    `
      :host {
        @apply grid grid-cols-[min-content_auto_min-content] gap-4 items-center;
        grid-template-areas: 'logo text action';
      }
    `,
  ],
})
export class AssistantCardComponent {
  public readonly logo = input.required<string>();
  public readonly name = input.required<string>();
  public readonly description = input.required<string>();
  public readonly status = input.required<AssistantStatusType>();
  public readonly isAdded = input(false);

  public readonly editClicked = output();
  public readonly addClicked = output();
  public readonly removeClicked = output();
  public readonly isEditable = computed(() => !this.isProcessingOrFailed());

  public readonly isProcessingOrFailed = computed(() =>
    ['processing', 'failed'].includes(this.status())
  );

  public handleEditClickedCTA(): void {
    if (this.isEditable()) {
      this.editClicked.emit();
    }
  }
}
